/**
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Locales } from '../../../core/language/Localize';
import { getUser } from '../../../core/redux/user.slice';
import ModalBody from '../Reusable/ModalWindow/ModalBody';
import ModalFooterButtons from '../Reusable/ModalWindow/ModalFooterButtons';
import ModalWindow from '../Reusable/ModalWindow/ModalWindow';
import TextButton from '../Reusable/TextButton/TextButton';
import TextField, { AUTOCOMPLETE } from '../Reusable/TextField/TextField';
import TextSelect from '../Reusable/TextSelect/TextSelect';
import './UpgradeFormModal.scss';
import { sendCompletedFormToHubspot } from '../../../api/hubspotApi';
import { setAlertBanner } from '../../../core/redux/uiSettings.slice';

interface LocalProps {
  open: boolean;
  setOpen: (visibility: boolean) => void;
}

const UpgradeFormModal = ({ open, setOpen }: LocalProps) => {
  const user = useSelector(getUser);
  const [email, setEmail] = useState<string>(user.email);
  const [organization, setOrganization] = useState<string>(user.orgBasic ?? '');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [lookingFor, setLookingFor] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [requestSentBody, setRequestSentBody] = useState<boolean>(false);
  const dispatch = useDispatch();

  const isValid = email !== '' && organization !== '' && phoneNumber !== '' && lookingFor !== '';

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValid) {
      setError('');
      setLoading(true);
      const body = {
        fields: [
          {
            name: 'email',
            value: email,
          },
          {
            name: 'company',
            value: organization,
          },
          {
            name: 'phone',
            value: phoneNumber,
          },
          {
            name: 'what_information_are_you_looking_for_',
            value: lookingFor,
          },
        ],
      };
      try {
        const isValidEmail = await sendCompletedFormToHubspot(body);
        if (isValidEmail) {
          setRequestSentBody(true);
        } else {
          setError(Locales.UpgradeForm.SubmissionEmailBlocked);
        }
      } catch (e: any) {
        if (e.message) {
          console.error(e.message);
        }
        setError(Locales.UpgradeForm.SubmissionUnsuccessful);
      } finally {
        setLoading(false);
      }
    }
  };

  function handleClickClose() {
    setOpen(false);
    dispatch(setAlertBanner(false));
  }

  return (
    <ModalWindow
      title={requestSentBody ? Locales.ThankYou : Locales.UpgradeNow}
      open={open}
      size="md"
      onCancelClick={() => setOpen(false)}
    >
      {!requestSentBody && (
        <form onSubmit={handleSubmit}>
          <ModalBody className="g-upgrade-form-container">
            <div className="g-upgrade-form-info-container">
              <h4 className="form-subheader">{Locales.UpgradeForm.UpgradeSpectra}</h4>
              <ul>
                <li>{Locales.UpgradeForm?.Info1}</li>
                <li>{Locales.UpgradeForm?.Info2}</li>
                <li>{Locales.UpgradeForm?.Info3}</li>
              </ul>
              <div className="g-upgrade-form-additional-info">
                <p>* Mandatory Fields</p>
                <p className="g-upgrade-form-additional-info__error">{error}</p>
              </div>
            </div>
            <div className="g-upgrade-form">
              <TextField
                id="upgrade-email"
                name="email"
                type="email"
                autocomplete={AUTOCOMPLETE.EMAIL}
                className="g-upgrade-form__upgrade-input"
                label={`${Locales.Email}*`}
                placeholder={Locales.Email}
                value={email}
                onChange={setEmail}
                required
              />
              <TextField
                id="upgrade-organization"
                name="organization"
                type="organization"
                autocomplete={AUTOCOMPLETE.ORGANIZATION}
                className="g-upgrade-form__upgrade-input"
                label={`${Locales.Organization}*`}
                placeholder={Locales.Organization}
                value={organization}
                onChange={setOrganization}
                required
              />
              <TextField
                id="upgrade-phone"
                name="phone"
                type="phone"
                autocomplete={AUTOCOMPLETE.PHONE}
                className="g-upgrade-form__upgrade-input"
                label={`${Locales.PhoneNumber}*`}
                placeholder={Locales.PhoneNumber}
                value={phoneNumber}
                onChange={setPhoneNumber}
                required
              />
            </div>
          </ModalBody>
          <ModalFooterButtons>
            <TextButton type="outlined" onClick={() => setOpen(false)}>
              {Locales.Close}
            </TextButton>
            <TextButton type="filled" loading={loading} disabled={!isValid} submit>
              {Locales.RequestMoreInformation}
            </TextButton>
          </ModalFooterButtons>
        </form>
      )}
      {requestSentBody && (
        <div>
          <ModalBody className="g-form-submitted-container">
            <span className="g-form-submitted-container__title">{Locales.ThankYou}</span>
            <span className="g-form-submitted-container__subtitle">{Locales.UpgradeForm.ThanksForSubmitting}</span>
            <span className="g-form-submitted-container__body-text"> {Locales.UpgradeForm.SubmissionSent}</span>
            <span className="g-form-submitted-container__body-text"> {Locales.UpgradeForm.KeepEnjoyingSpectra}</span>
          </ModalBody>
          <ModalFooterButtons>
            <TextButton type="filled" onClick={handleClickClose}>
              {Locales.Close}
            </TextButton>
          </ModalFooterButtons>
        </div>
      )}
    </ModalWindow>
  );
};
export default UpgradeFormModal;
